import { createApp } from 'vue';
import Calendar from './components/calendar.vue';
import Artworks from './components/artworks.vue';
import Artists from './components/artists.vue';
import en from './plugin-i10n-en';
import fr from './plugin-i10n-fr';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);

let API_URL;
let artistUrl;
let artsysUrl;

const updateConstants = lang => {
    let isDev = false;

    if (location.hostname.indexOf("localhost") != -1) {
        artsysUrl = "http://localhost:8043";
        isDev = true;
    }
    else if (location.hostname.indexOf("artsys-st.artch.org") != -1) {
        artsysUrl = "https://artsys-st.artch.org";
    }
    else {
        artsysUrl = "https://artsys.artch.org";
    }

    API_URL = artsysUrl + "/api/";
    artistUrl = isDev ? "" : (lang == "fr" ? "/artistes" : "/artists");
}

window.loadCalendar = (nodeId, lang, listOption = true) => {
    updateConstants(lang);

    const app = createApp(Calendar).component('font-awesome-icon', FontAwesomeIcon)
    const comp = app.mount('#' + nodeId)
    comp.setData(lang, API_URL, lang == "en" ? en : fr, listOption);
}

window.loadArtworks = (nodeId, lang) => {
    updateConstants(lang);

    const app = createApp(Artworks);
    const comp = app.mount("#" + nodeId);
    comp.setData(lang, artistUrl, artsysUrl, API_URL, lang == "en" ? en : fr);
}

window.loadArtists = (nodeId, lang) => {
    updateConstants(lang);

    const app = createApp(Artists)
    const comp = app.mount("#" + nodeId);
    comp.setData(lang, artistUrl, artsysUrl, API_URL, lang == "en" ? en : fr);
}