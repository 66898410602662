<template>
	<div v-if="event" class="modal-container">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="event-type">
						{{ event.type }}
					</div>
					<button @click="dismissModal()" type="button" class="close-container" aria-label="close">
						<img src="/img/close.png" />
					</button>
				</div>
				<div class="modal-body">
					<div class="d-flex justify-content-between">
						<div class="flex-2">
							<h5 class="modal-title">{{ event.title }}</h5>
							<div class="event-date">
								<small v-if="event.start == event.end">
									{{ t["date"] }} : {{ event.start }}
								</small>
								<small v-else>
									{{ t["from"] }} : {{ event.start }} {{ t["to"] }} {{ event.end }}
								</small>
								<div v-html="event.description"></div>
							</div>
						</div>
						<div class="flex-1 d-flex flex-column justify-content-between text-right">
							<img v-if="event.image" :src="event.image" class="modal-image rounded mx-sm-auto float-lg-left shadow-sm">

							<div v-if="event.external_link">
								<a class="btn btn-primary btn-md mt-3 mb-3" :href="event.external_link">{{ t["findOutMore"] }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		name: "CalendarModal",
		props: ["event", "dismissModal", "t"]
    }
</script>

<style scoped>
	.event-type {
		border:2px solid black;
		border-radius: 25px;
		padding:7px 20px;
		text-transform: uppercase;
	}

	h5 {
		text-transform: uppercase;
		font-size: 2.25rem;
		line-height: 1.1;
	}

	.modal-header {
		border-bottom: 0px solid white;
	}

	.modal-container {
		z-index: 5;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.356);
		animation: fadeIn 0.25s 1 forwards ease-out;
	}

	.modal-dialog {
		opacity: 0;
		max-width: 70vw;
		animation:
			fadeIn 0.2s 0.1s 1 forwards,
			scale 0.2s 0.1s 1 forwards
	}

	.modal-body {
		max-height: 70vh;
		overflow: auto;
	}

	.modal-image {
		max-width: 27vw;
		height: auto;
	}

	.link-container {
		background-color: #fafafa;
		font-size: 0.9em;
	}

	.modal-content {
		border:2px solid black;
		border-radius: 15px;
		padding:20px;
	}

	.close-container {
		background: transparent;
		border:0px solid white;
	}

	.close-container img {
		max-height: 35px;
	}

	@media screen and (max-width: 992px) {
		.modal-dialog {
			max-width: 95vw;
		}

		.modal-body {
			display: flex;
			flex-direction: column-reverse;
		}

		.modal-image {
			min-width: 75vw;
			height: auto;
		}
    }

	@keyframes fadeIn {
		0% {opacity : 0}
		100% {opacity : 1}
	}

	@keyframes scale {
		0% { transform: scale(0.9); }
		100% { transform: scale(1); }
	}
</style>