<template>
	<div ref="rootNode">
		<div class="container cal-m-bottom">
			<select v-model="eventFilter" name="event-filter" id="event-filter" class="form-control">
				<option value="FilterByType" selected disabled hidden>{{ t["filterByType"] }}</option>
				<option v-for="eventType in eventTypes"
					:value="eventType.value"
					v-text="eventType.text"
					:key="eventType.value">
				</option>
			</select>
		</div>
		<div class="container">
			<div id="inner-full-cal-container" :class="forceSmallView ? 'd-block' : 'd-flex flex-column flex-md-row'">
				<FullCalendar class="col px-0" ref="fullCalendar" :options="calendarOptions" custom-buttons="customButtons" header="header" />
				<div v-if="showListEvents && !forceSmallView" class="col col-md-3 ml-md-2 p-lg-2 d-flex flex-column list-events rounded">
					<h4>
						{{ t["EventListTitle"] }}
					</h4>
					<div v-for="calendarEvent in listEvents" :key="calendarEvent.id">
						<div
							class="mb-2 p-2 text-white shadow fc-event list-events-item"
							@click.stop.prevent="openEventModal(calendarEvent)"
							:style="{ backgroundColor: calendarEvent.color }">
							<div class="calendar-event-title"><strong>{{ calendarEvent.title }}</strong></div>
							<div class="faded"><small>{{ calendarEvent.start }} - {{ calendarEvent.realEnd }}</small></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="mt-1 sm-hide">
				<div class="d-flex justify-content-between px-2 py-1 flex-wrap">
					<div v-for="eventType in strictlyEventTypes" :key="eventType.value" class="d-flex align-items-center legend-cell">
						<div class="mb-1 legend-color mx-2" :style="{ backgroundColor: this.setEventColor(eventType.value) }"></div>
						<div class="fc-event-title legend-text" v-text="eventType.text"></div>
					</div>
				</div>
			</div>
		</div>
		<CalendarModal v-if="selectedEvent" :event="selectedEvent" :dismissModal="dismiss" :t="t" />
	</div>
</template>

<script>
	import '@fullcalendar/core/vdom'
	import FullCalendar from '@fullcalendar/vue3'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import listPlugin from '@fullcalendar/list'
	import interactionPlugin from '@fullcalendar/interaction'
	import bootstrap5Plugin from '@fullcalendar/bootstrap5'
	import frLocale from '@fullcalendar/core/locales/fr'
	import CalendarModal from './calendar-modal.vue'

	export default {
		components: {
			FullCalendar,
			CalendarModal,
		},
		data() {
			return {
				name : "Calendar",
				t : [],
				eventTypes : [],
				eventFilter : "FilterByType",
				selectedEvent : null,
				showListEvents : false,
				listEvents : [],
				currentDate : null,
				forceSmallView : false,
				calendarOptions: {
					events: [],
					plugins: [
						dayGridPlugin,
						listPlugin,
						interactionPlugin,
						bootstrap5Plugin
					],
					initialView: 'dayGridMonth',
					locales: [frLocale],
					timeZone: 'America/Montreal',
					headerToolbar: {
						left: 'title',
						center: '',
						right: 'today prev,next'
					},
					customButtons: {
						prev: {
							click: () => {
								this.calendarApi.prev();
								let selectedMonth = this.calendarApi.getDate();
								this.fetchRequest(selectedMonth, 2, true, false);
							}
						},
						next: {
							click: () => {
								this.calendarApi.next();
								let selectedMonth = this.calendarApi.getDate();
								this.fetchRequest(selectedMonth, 2, false, true);
							}
						},
						today: {
							click: () => {
								this.calendarApi.today();
								let selectedMonth = this.calendarApi.getDate();
								this.fetchRequest(selectedMonth, 0, false, false);
							}
						},
					},
					eventClick: info => {
						info.jsEvent.preventDefault();
						this.openEventModal(info.event);
					}
				}
			}
		},
		mounted() {
			this.calendarApi = this.$refs.fullCalendar.getApi();
			this.monthCache = new Set();
			this.eventCache = new Set();

			window.addEventListener("resize", () => {
				this.forceSmallView = this.$refs.rootNode.offsetWidth < 700;
				this.calendarOptions.headerToolbar.right = this.forceSmallView ? "prev,next" : "today prev,next"
				this.calendarOptions.height = this.forceSmallView ? 'auto' : null;
			})
		},
		watch: {
			eventFilter(selectedFilter) {
				this.selectedFilter = selectedFilter;
				this.calendarOptions.events.forEach(calendarEvent => {
					calendarEvent.display = selectedFilter == "All" || calendarEvent.type == selectedFilter ? "auto" : "none";
				});

				this.setListEvents(this.getSelectedMonth());
			},
		},
		computed: {
			strictlyEventTypes: function() {
				return this.eventTypes.filter(eventType => eventType.value != "All");
			},
			currentMonth: function() {
				return this.currentDate.getMonth();
			},
		},
		methods : {
			setData(lang, apiUrl, translations, listOption) {
				this.lang = lang;
				this.calendarOptions.locale = lang;
				this.apiUrl = apiUrl;
				this.t = translations;
				this.selectedFilter = "All";
				this.listOption = listOption;
				this.showListEvents = listOption;
				this.calendarOptions.customButtons.today.text = this.t["today"];
				window.dispatchEvent(new Event('resize'));

				this.eventTypes = [
					{ value: "All", text: this.t["all"] },
					{ value: "Subvention", text: this.t["subvention"] },
					{ value: "Call For Project", text: this.t["callForProject"] },
					{ value: "Exposition", text: this.t["exposition"] },
					{ value: "Workshop", text: this.t["workshop"] },
					{ value: "Conference", text: this.t["conference"] }
				];

				this.eventColorPalette = {
					subvention: '#9d76ea',
					call: '#495c15',
					exposition: '#d46840',
					workshop: '#cbbca5',
					conference: '#000000'
				};

				this.currentDate = new Date();
				const firstDay = new Date(this.currentDate.getFullYear(), this.currentMonth, 1);
				this.fetchRequest(firstDay, 2, true, true);
			},
			fetchRequest(date, fetchRange = 1, prev = false, next = false) {
				date.setDate(date.getDate() + 1);

				let minRange = prev ? -fetchRange : 0;
				let maxRange = next ? fetchRange : 0;

				for (let index = minRange; index <= maxRange; index++) {
					let fetchedMonth = new Date(date);

					fetchedMonth.setMonth(fetchedMonth.getMonth() + index);
					const month = fetchedMonth.getMonth();
					const firstDay = new Date(fetchedMonth.getFullYear(), month, 1);

					this.getMonthUrl(firstDay);

					if (!this.monthCache.has(this.requestedMonthUrl)) {
						this.monthCache.add(this.requestedMonthUrl);
						this.fetchEvents(month);
					}
					else if (index == 0) {
						this.setListEvents(month);
					}
				}
			},
			fetchEvents(month) {
				fetch(this.apiUrl + "calendar" + this.requestedMonthUrl, {
					method: "GET",
					credentials: 'omit',
					mode: 'cors'
				})
				.then(response => response.json())
				.then(data => {
					for (let event of data) {
						if (!this.eventCache.has(event.id)) {
							this.eventCache.add(event.id)
							const eventTitle = this.lang == 'fr' ? event.title_fr : (event.title_en ? event.title_en : event.title_fr);
							const eventDescription = this.lang == 'fr' ? event.description_fr : (event.description_en ? event.description_en : event.description_fr);

							event.display = this.selectedFilter === "All" || event.type === this.selectedFilter ? "auto" : "none";

							let endDate = new Date(Date.parse(event.date_end));
							endDate.setDate(endDate.getDate() + 1);

							this.calendarOptions.events.push({
								id: event.id,
								title: eventTitle,
								description: eventDescription,
								start: event.date_start,
								realEnd : event.date_end,
								end: endDate.toISOString().slice(0,10),
								months: [month],
								external_link: event.external_link,
								image: event.image_name,
								status: event.status,
								type: event.type,
								color: this.setEventColor(event.type),
								display: event.display
							})
						}
						else {
							const foundEvent = this.calendarOptions.events.find(el => el.id == event.id);

							(!foundEvent.months.includes(month)) && foundEvent.months.push(month);
						}
					}

					new Date().getMonth() == month && this.setListEvents(month);
				});
			},
			setListEvents(month) {
				this.listEvents = this.calendarOptions.events.filter(el => el.months.includes(month));

				if (this.selectedFilter != "All") {
					this.listEvents = this.listEvents.filter(el => el.type == this.selectedFilter);
				}

				window.dispatchEvent(new Event('resize'));
			},
			getSelectedMonth() {
				let date = this.calendarApi.getDate()
				date.setDate(date.getDate() + 1);
				return date.getMonth();
			},
			setEventColor(type) {
				const colorByType = new Map([
					["Subvention", this.eventColorPalette.subvention],
					["Call For Project", this.eventColorPalette.call],
					["Exposition", this.eventColorPalette.exposition],
					["Workshop", this.eventColorPalette.workshop],
					["Conference", this.eventColorPalette.conference]
				]);

				return colorByType.get(type);
			},
			getMonthUrl(date) {
				date.setDate(date.getDate());
				this.year = date.getFullYear();
				this.month = date.getMonth() + 1;
				this.firstDay = new Date(this.year, this.month, 1).getDate();
				this.lastDay = new Date(this.year, this.month, 0).getDate();

				this.month = this.month.toString().padStart(2, 0);
				this.firstDay = this.firstDay.toString().padStart(2, 0);

				this.firstDayUrl = "/" + [this.year, this.month, this.firstDay].join('-');
				this.lastDayUrl = "/" + [this.year, this.month, this.lastDay].join('-');

				this.requestedMonthUrl = this.firstDayUrl + this.lastDayUrl;
			},
			openEventModal(calendarEvent) {
				if (calendarEvent.extendedProps) {
					calendarEvent.image = calendarEvent.extendedProps.image;
					calendarEvent.description = calendarEvent.extendedProps.description;
					calendarEvent.external_link = calendarEvent.extendedProps.external_link;
					calendarEvent.type = calendarEvent.extendedProps.type;
				}

				let evt = {...calendarEvent};
				evt.title = calendarEvent.title;
				evt.type = calendarEvent.type;

				evt.start = calendarEvent.start instanceof Date ? calendarEvent.start.toISOString().slice(0,10) : calendarEvent.start;

				let endDate = calendarEvent.end instanceof Date ? calendarEvent.end : new Date(Date.parse(calendarEvent.end));
				endDate.setDate(endDate.getDate() - 1);
				evt.end = endDate.toISOString().slice(0,10);

				this.selectedEvent = evt;
			},
			dismiss() {
				this.selectedEvent = null;
			}
		},
	};
</script>

<style>
	h4 {
		margin-top:50px;
		margin-bottom: 10px;
		border-bottom: 0px solid white;
		font-size: 1.25rem;
		text-transform: uppercase;
	}

	.calendar-event-title {
		text-transform: uppercase;
	}

	.fc-scrollgrid  {
		border-radius: 11px;
		border: 2px solid black !important;
	}

	.fc-daygrid-event {
		border-radius: 15px;
		text-align: center;
		font-size: 0.7rem;
		font-weight: 300;
	}
	.fc-button-primary {
		border-radius: 50% !important;
		background-color: white !important;
		color: black !important;
		height:30px;
		width:30px;
		font-size: 20px;
		padding:0 !important;
		margin: 0 5px !important;
	}

	.fc-today-button {
		width:auto;
		border-radius: 15px !important;
		padding:0 10px !important;
	}


	.fc-button-primary:hover {
		background-color: black !important;
		color: white !important;
	}

	.fc-scrollgrid tbody th,
	.fc-scroller-harness {
		border-radius: 9px 9px 0 0;
	}

	.fc-theme-standard td,
	.fc-theme-standard th {
		border:1px solid black;
	}

	thead {
		background-color: #e5ddd2;
		text-transform: uppercase;
	}

	#event-filter {
		max-width: 250px;
		border-color: black;
		border-width: 2px;
		padding: 7px 20px 7px 20px;
		border-radius: 25px 25px 25px 25px;
	}

	.fc-event:hover {
		cursor: pointer;
		font-weight: bold;
	}

	.fc-event-title {
		font-size: 1.1em;
	}

	.fc-list-event-time {
  		display:none;
	}

	.cal-m-bottom {
		margin-bottom: 0.85em;
	}

	.list-events {
		background-color: rgb(253, 253, 253);
		row-gap: 0.6em;
		padding: 0;
		box-sizing: border-box;
	}

	.list-events .list-events-item {
		box-sizing: border-box;
		border: 0.25em solid transparent;
		transition: border-color 0.1s ease-in;
		border-radius: 15px;
	}

	.list-events .list-events-item:hover, .list-events-item:focus {
		border-color: black;
	}

	.legend-cell {
		flex-basis: 46%;
	}

	.legend-color {
		width: 25px;
		height: 25px;
		max-width: 2.75em;
		max-height: 2.75em;
		border-radius: 50%;
	}

	.legend-text {
		font-size: 0.8em;
	}

	@media screen and (max-width: 1197px) {
		.fc-event-title {
			font-size: 0.9em;
		}
    }

	@media screen and (max-width: 991px) {
		.fc-event-title {
			font-size: 0.8em;
		}
    }

	@media screen and (max-width: 767px) {
		.fc-event-title {
			font-size: 0.7em;
    	}

		.list-events {
			row-gap: 0.6em;
			padding: 0.5em 0;
		}

		.sm-hide {
			display: none;
		}
	}
</style>