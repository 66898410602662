<template>
    <div v-if="loaded">
        <div v-if="currentArtist == null">
            <div class="artsy-artists-years">
                {{ t["discoverOtherYears"] }}
                <a v-for="year in years" v-bind:key="year" href="javascript:void(0)" @click="changeArtsysYear(year)" :class="'pill artsys-year-link ' + (year == currentYear ? 'year-link-selected' : '' ) ">
                    {{ year }}
                </a>
            </div>

            <div class="artsys-artists-list">
                <div v-for="l in artists" v-bind:key="l.id" :class="'artsys-artch-year-' + l.artch_year" :style="l.artch_year == currentYear ? '' : 'display:none'">
                    <div class="artsys-artist-tile-img">
                        <a :href="'?artist=' + l.id">
                            <span :style="l.picture_url != null ? 'background-image:url(' + l.picture_url + ');' : ''">&nbsp;</span>
                        </a>
                    </div>
                    <h4 class="artsys-artist-tile-name">
                        <a :href="'?artist=' + l.id">
                            {{ l.artist_name }}
                        </a>
                    </h4>
                    <div class="artsys-artist-tile-type">
                        {{ l["discipline_" + lang] }}
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="artsys-artist-header">
                <div class="artsys-artist-container">
                    <div class="box">
                        <div class="artist-name-container artist-page-content-container">
                            <div class="artist-name-disc">
                                <h2>{{ currentArtist.artist_name }}</h2>
                                <div>{{ currentArtist["discipline" + lang] }}</div>
                            </div>
                            <div class="back-link-container">
                                <a class="pill back-link" :href="backToArtist && artistId != null ? '?artist=' + artistId : '?'">
                                    <span v-if="backToArtist && artistId != null">
                                        {{ t["back-to-artist"] }}
                                    </span>
                                    <span v-else>
                                        {{ t["back"] }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="artsys-artist-header-intro">
                        <div class="artist-page-content-container">
                            <div class="artsys-artist-header-bio">
                                <h3>{{ t["bio"] }}</h3>
                                <div class="artsys-artist-header-bio-content">
                                    <div v-html="currentArtist['biography_' + lang].replace(/\\n/g, '<br>')"></div>
                                </div>
                                <h5>{{ t["discover"] }}</h5>
                                <div class="artsys-artist-external-link">
                                    <a class="pill me-2" v-if="currentArtist.facebook_url != null && currentArtist.facebook_url.length > 0" :href="currentArtist.facebook_url" target="artist_page">Facebook</a>
                                    <a class="pill me-2" v-if="currentArtist.instagram_url != null && currentArtist.instagram_url.length > 0" :href="currentArtist.instagram_url" target="artist_page">Instagram</a>
                                    <a class="pill me-2" v-if="currentArtist.website_url != null && currentArtist.website_url.length > 0" :href="currentArtist.website_url" target="artist_webpage">{{ t["website"] }}</a>
                                    <a class="pill me-2" href="javascript:void(0)" v-if="currentArtist.artworks.length > 0" @click="showArtwork(0, true)">{{ t["artworks"] }}</a>
                                </div>
                            </div>
                            <div class="artsys-artist-header-image">
                                <img :src="currentArtist.picture_url" alt="{{ currentArtist.artist_name }}" onerror="this.parentNode.remove()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="artworkId != null">
                <div v-if="artworkId == 0">
                    <div class="artsys-artworks-list">
                        <div v-for="artwork in currentArtist.artworks" v-bind:key="artwork.id">
                            <div class="artsys-artwork-tile-img">
                                <a href="javascript:void(0)" @click="showArtwork(artwork.id)">
                                <img :src="artwork.images[0]" :alt="artwork.title">
                                </a>
                            </div>
                            <div class="artsys-artwork-tile-name">
                                <a href="javascript:void(0)" @click="showArtwork(artwork.id)">
                                    {{ artwork.title }}

                                    <span v-if="artwork.display_status == 'Sold'" class="artwork-sold">
                                        ({{ t["sold"] }})
                                    </span>
                                </a>
                                <br>
                                {{ t["by"] }} {{ artwork.artist_name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="artsys-artwork-single-page">
                        <iframe :src="artsysUrl + '/extras/artwork/artwork.php?artwork=' + artworkId + '&lang=' + lang" frameborder="0" style="width:100%;height:100%" @load="resizeIframe($event)"></iframe>
                    </div>
                </div>
            </div>
            <div v-else class='artist-page-approach-container'>
                <div class="artist-page-content-container">
                    <h3>{{ t["process"] }}</h3>
                    <div class="artsys-artist-approach" v-html="currentArtist['artistic_process_' + lang].replace(/\\n/g, '<br>')"></div>
                    <div v-if="currentArtist.artworks.length > 0" class="artsys-artist-image-container">
                        <div class="artsys-artist-images">
                            <a v-bind:key="i" v-for="(artwork, i) in currentArtist.artworks.filter(a => a.on_profile_page == 1)" :href="'?artist=' + currentArtist.id + '&artwork=' + artwork.id + '&back-to-artist=1'">
                                <img :src="artwork.images[0]" alt="Artwork image" onerror="this.style.display='none'">
                            </a>
                        </div>
                        <div class="go-to-artworks-container">
                            <a href='javascript:void(0)' @click="showArtwork(0)">
                                <img :src="artsysUrl + '/img/plugin/right-arrow.png'" alt='right-arrow' style='max-height:45px' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const findGetParameter = parameterName => {
        let result = null;
        let tmp = [];

        location.search.substr(1).split("&").forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });

        return result;
    }

    export default {
        data() {
            return {
                artists: [],
                years : [],
                artistId : null,
                backToArtist : false,
                currentYear : null,
                currentArtist : null,
                artworkId : null,
                lang : "en",
                t : [],
                loaded : false,
                showroomUrl : null,
                apiUrl : null
            }
        },
        methods: {
            setData(lang, artistUrl, artsysUrl, apiUrl, translations) {
                this.lang = lang;
                this.artsysUrl = artsysUrl;
                this.artistUrl = artistUrl;
                this.apiUrl = apiUrl;
                this.t = translations;

                fetch(this.apiUrl + "artists", {
                    method: "GET",
                    credentials: 'omit',
                    mode: 'cors'
                })
                .then(response => response.json())
                .then(data => {
                    let highestYear = 0;
                    this.currentYear = 0;
                    this.years = [];

                    data.forEach(artist => {
                        if (artist.artch_year > highestYear) {
                            highestYear = artist.artch_year;
                        }

                        if (artist.artch_year != this.currentYear) {
                            this.currentYear = artist.artch_year;
                            this.years.push(this.currentYear);
                        }
                    })

                    if (highestYear > 0) {
                        this.currentYear = highestYear;
                        this.artistId = findGetParameter("artist");
                        this.artworkId = findGetParameter("artwork");
                        this.backToArtist = findGetParameter("back-to-artist") == "1";
                        this.artists = data;

                        if (this.artistId != null) {
                            this.setArtist(this.artistId, true);
                        }
                        else {
                            this.loaded = true;
                        }
                    }
                })
            },
            changeArtsysYear(year) {
                this.currentYear = year;
            },
            setArtist(id) {
                if (id != null && id > 0) {
                    fetch(this.apiUrl + "artists/" + id + "/true", {
                        method: "GET",
                        credentials: 'omit',
                    })
                    .then(response => response.json())
                    .then(data => {
                        this.currentArtist = data;
                        this.loaded = true;
                    })
                }
                else {
                    this.currentArtist = null;
                }
            },
            showArtwork(id, backToArtist = false) {
                window.location.href = "?artist=" + this.currentArtist.id + "&artwork=" + id + (backToArtist ? "&back-to-artist=1" : "");
            },
            resizeIframe(evt) {
                let target = evt.target;

                function receiveSize(e){
                    let newHeight = e.data + 35;
                    target.style.height = newHeight + "px";
                }

                window.addEventListener("message", receiveSize, false);
            }
        }
    }
</script>
