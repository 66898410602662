<template>
    <div>
        <div class="artsys-filters">
            <select class="pill" style="flex-basis:150px" v-model="yearFilter" @change="refreshData(1, true, true)">
                <option value="all">{{ t["allYears"] }}</option>
                <option v-for="year in years" :value="year">{{ year }}</option>
            </select>
            <select class="pill" style="flex-basis:250px" v-model="artistFilter" @change="refreshData(1, true)">
                <option value="all">{{ t["allArtists"] }}</option>
                <option v-for="artist in artists" :value="artist.id">{{ artist.artist_name }}</option>
            </select>
            <select class="pill" v-model="artworkStateFilter" @change="refreshData(1, true)">
                <option value="all">{{ t["filterArtworkStateAll"] }}</option>
                <option value="available">{{ t["filterArtworkStateAvailable"] }}</option>
            </select>
            <select class="pill" v-model="priceFilter" @change="refreshData(1, true)">
                <option value="all">{{ t["allPrices"] }}</option>
                <option value="0-50">0$ - 50$</option>
                <option value="51-100">51$ - 100$</option>
                <option value="101-200">101$ - 200$</option>
                <option value="201-500">201$ - 500$</option>
                <option value="501-+">{{  t["over 500"]  }}</option>
            </select>
        </div>
        <div class="artsys-pagination">
            <a :class="'pill back-link me-2 ' + (pageNumber <= 1 ? 'faded' : '')" href="javascript:void(0)" @click="refreshData(pageNumber - 1)">{{ t["previousPage"] }}</a>
            <span class="hide-xs" v-if="total > 0 || !loaded">
                {{ total }} {{ t["artwork count"]  }} ({{ t["page"] }} {{ pageNumber }} {{ t["of"] }} {{ pageCount }})
            </span>
            <span v-else>
                {{ t["no artworks"] }}
            </span>
            <a :class="'pill back-link ' + (pageNumber >= pageCount ? 'faded' : '')" href="javascript:void(0)" @click="refreshData(parseInt(pageNumber) + 1)">{{ t["nextPage"] }}</a>
        </div>
        <div class="artsys-artworks-list">
            <div v-for="artwork in artworks" v-bind:key="artwork.id">
                <div class="artsys-artwork-tile-img">
                    <a :href="artistUrl + '?artist=' + artwork.id_user + '&artwork=' + artwork.id">
                        <img :src="artwork.images[0]" :alt="artwork.title">
                    </a>
                </div>
                <h4 class="artsys-artwork-tile-name">
                    <a :href="artistUrl + '?artist=' + artwork.id_user + '&artwork=' + artwork.id">
                        {{ artwork.title }}

                        <span v-if="artwork.display_status == 'Sold'" class="artwork-sold">
                            ({{ t["sold"] }})
                        </span>
                    </a>
                </h4>
                <div>
                    {{ t["by"] }} {{ artwork.artist_name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                artworks : [],
                artsysUrl : "",
                artistUrl : "",
                apiUrl : null,
                pageNumber: 1,
                pageCount : 2,
                artworkStateFilter : "all",
                priceFilter: "all",
                years: [],
                yearFilter : "all",
                artists: [],
                artistFilter: "all",
                t : [],
                total : 0,
                loaded : false,
            }
        },
        methods : {
            setData(lang, artistUrl, artsysUrl, apiUrl, translations) {
                this.lang = lang;
                this.artsysUrl = artsysUrl;
                this.artistUrl = artistUrl;
                this.apiUrl = apiUrl;
                this.t = translations;
                let page = location.hash.replace("#", "");

                if (page == "" || isNaN(page)) {
                    page = 1;
                }

                this.pageCount = page;
                this.refreshData(page);

                this.loadArtists();
                this.loadYears();
            },
            loadYears() {
                fetch(this.apiUrl + "artworks/years", {
                    method: "GET",
                    credentials: 'omit',
                    mode: 'cors'
                })
                .then(response => response.json())
                .then(data => {
                    this.years = data;
                });
            },
            loadArtists() {
                fetch(this.apiUrl + "artworks/artists/" + this.yearFilter, {
                    method: "GET",
                    credentials: 'omit',
                    mode: 'cors'
                })
                .then(response => response.json())
                .then(data => {
                    this.artists = data;
                });
            },
            refreshData(pageNumber, forceRefresh = false, clearArtistFilter = false) {
                if (clearArtistFilter) {
                    this.artistFilter = "all";
                    this.loadArtists();
                }

                if (pageNumber >= 1 && (pageNumber <= this.pageCount || forceRefresh)) {
                    this.pageNumber = pageNumber;
                    location.hash = pageNumber;

                    fetch(this.apiUrl + "artworks/p/" + this.pageNumber + "/" + this.artworkStateFilter + "/" + this.priceFilter + "/" + this.artistFilter + "/" + this.yearFilter, {
                        method: "GET",
                        credentials: 'omit',
                        mode: 'cors'
                    })
                    .then(response => response.json())
                    .then(data => {
                        this.loaded = true;
                        this.total = data.total;
                        this.pageCount = Math.ceil(data.total/data.pageSize);
                        this.artworks = data.artworks;
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .artsys-filters {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        gap: 10px;
        flex-wrap: wrap;
    }

    .artsys-pagination {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        gap: 10px;
        align-items: center;
    }

    .faded {
        opacity: 0.5;
    }
</style>